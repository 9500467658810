<template>
  <div class="h-screen">
    <div class="htmlFont w-1/2 mx-auto h-full flex justify-center items-center">
      <row class-name="rounded shadow">
        <i-col class-name="mx-4 p-4 text-center">
          <span
            class="text-3xl text-blue-display font-roboto font-extrabold tracking-tight"
          >
            <i class="fad fa-check-double text-indigo-600"></i>
            <br />Welcome to the club!
          </span>

          <p class="text-blue-medium text-base mt-8 mb-8">
            Thanks for signing up for a Save2Go account. Visit us in-store to
            get your account number along with instructions on how to make your
            first deposit. Trip Travel in the Washington Mall.
          </p>
          <Button type="primary" long class="mb-2" size="large" to="/"
            >Done</Button
          >
          <div class="w-1/2 my-4 mx-auto">
            <social-sharing
              url="save2go.trip.bm"
              title="Pay for your trip overtime"
              description="A flexible and simple way to save money towards your next trip."
              quote="Pay for your trip over time"
              hashtags="save2go,save,tripbda, travel"
              twitter-user="tripbda"
              inline-template
            >
              <div>
                <network network="facebook">
                  <i-button primary="primary" class="mx-2">
                    <i class="fab fa-facebook"></i>
                  </i-button>
                </network>
                <network network="twitter">
                  <i-button primary="primary" class="mx-2">
                    <i class="fab fa-twitter"></i>
                  </i-button>
                </network>
                <!-- <network network="whatsapp">
                <i class="fa fa-fw fa-whatsapp"></i> Whatsapp
              </network> -->
              </div>
            </social-sharing>
          </div>
        </i-col>
      </row>
    </div>
  </div>
</template>

<script>
export default {
  name: "ThankYou",
  data() {
    return {};
  }
};
</script>

<style scoped></style>
