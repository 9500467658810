<template>
  <nav class="flex items-center justify-between flex-wrap bg-white p-3 border">
    <div class="flex items-center flex-shrink-0 text-white mr-6">
      <img class="h-5 w-auto" src="../assets/images/logo-single.svg" />
    </div>

    <div class="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
      <div class="lg:flex-grow">
        <Button
          type="text"
          class="float-right block lg:inline-block lg:mt-0 text-blue-display hover:text-white font-normal"
        >
          <span class="font-hairline">Account</span>
        </Button>
        <Button
          type="text"
          class="float-right block lg:inline-block lg:mt-0 text-blue-display hover:text-white border border-blue-500 pb-1"
        >
          <i class="fas fa-bell text-sm py-0"></i>
        </Button>
        <Button
          type="text"
          class="float-right block lg:inline-block lg:mt-0 text-blue-display hover:text-white"
        >
          <i class="fas fa-life-ring"></i>
        </Button>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavLight",
  data() {
    return {};
  }
};
</script>

<style scoped></style>
