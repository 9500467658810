<template>
  <div class="absolute top left w-full">
    <div>
      <router-link to="/">
        <img class="m-4" src="../assets/images/logo-dark.svg" width="40" />
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "PublicHeader"
};
</script>

<style></style>
