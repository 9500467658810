import Vue from "vue";
import Router from "vue-router";
import Landing from "./components/Landing.vue";
import SignUp from "./components/SignUp.vue";
import ThankYou from "./components/ThankYou.vue";
import Main from "./components/Main.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "landing",
      component: Landing
    },
    {
      path: "/signup",
      name: "signup",
      component: SignUp
    },
    {
      path: "/signup/thankyou",
      name: "thankyou",
      component: ThankYou
    },
    {
      path: "/main",
      name: "Main",
      component: Main
    }
  ]
});
