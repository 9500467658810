import Vue from "vue";

import router from "@/router";
import App from "@/App.vue";
import * as VeeValidate from "vee-validate";
import { ValidationProvider } from "vee-validate/dist/vee-validate.full";

import "@/assets/css/split.css";
import "@/assets/css/main.css";
import "@fortawesome/fontawesome-pro/css/all.css";
import "animate.css/animate.min.css";
import "@fortawesome/fontawesome-pro/js/all.js";
var SocialSharing = require("vue-social-sharing");

import ViewUI from "view-design";
import locale from "view-design/dist/locale/en-US";
import "view-design/dist/styles/iview.css";
import "../theme/dist/iview.css";

import SweetAlertIcons from "vue-sweetalert-icons";

Vue.use(SweetAlertIcons);
Vue.use(ViewUI, { locale });
Vue.use(VeeValidate);
Vue.use(SocialSharing);

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", VeeValidate.ValidationObserver);
Vue.component("Col", ViewUI);

Vue.config.productionTip = false;

new Vue({ router, render: h => h(App) }).$mount("#app");
