<template>
  <div class="htmlFont">
    <!--
        SITE LOGO
    -->

    <row :gutter="16">
      <i-col span="24">
        <!--
        LEAD IN
        -->
        <div class="split-intro">
          <span
            class="block text-3xl text-blue-display font-roboto font-extrabold tracking-tight mb-6 xs:mt-10 sm:mt-10 md:mt-10 lg:mt-0 xl:mt-0"
            >New Account</span
          >
        </div>
      </i-col>
    </row>
    <!--
        REGISTER FORM
    -->
    <!-- START FORM -->
    <row>
      <i-col span="24">
        <ValidationObserver
          name="save2go"
          ref="observer"
          tag="form"
          v-slot="{ invalid }"
          @submit.prevent="submit()"
        >
          <!-- <i-form id="save2go" label-position="top" method="POST"> -->
          <i-form
            id="save2go"
            label-position="top"
            method="POST"
            action="https://usebasin.com/f/c9d8b53f5fbd"
          >
            <!-- START FULL NAME  -->

            <row :gutter="16">
              <i-col>
                <ValidationProvider
                  name="name"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <form-item label-for="name" label="Full Name">
                    <Input
                      name="name"
                      type="text"
                      size="large"
                      autofocus
                      v-model="form.name"
                    />

                    <span class="text-sm block text-indigo-600">
                      {{ errors[0] }}
                    </span>
                  </form-item>
                </ValidationProvider>
              </i-col>
            </row>
            <!-- END FULL NAME  -->

            <row :gutter="16">
              <!-- START EMAIL  -->
              <i-col>
                <ValidationProvider
                  name="email"
                  rules="required|email"
                  v-slot="{ errors }"
                >
                  <form-item label-for="email" label="Email">
                    <Input
                      name="email"
                      size="large"
                      v-model="form.email"
                      class="w-full"
                    />

                    <span class="text-sm block text-indigo-600">
                      {{ errors[0] }}
                    </span>
                  </form-item>
                </ValidationProvider>
              </i-col>
            </row>
            <!-- END EMAIL  -->

            <row :gutter="16">
              <!-- START BIRTHDAY  -->
              <i-col span="12">
                <ValidationProvider
                  name="dob"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <form-item label-for="dob" label="DOB">
                    <date-picker
                      v-model="form.dob"
                      name="dob"
                      format="dd/MM/yyyy"
                      type="date"
                      size="large"
                      class="w-full"
                    />

                    <span class="text-sm block text-indigo-600">
                      {{ errors[0] }}
                    </span>
                  </form-item>
                </ValidationProvider>
              </i-col>
              <!-- END BIRTHDAY  -->

              <!-- START PHONE  -->
              <i-col span="12">
                <ValidationProvider
                  name="phone"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <form-item label-for="phone" label="Phone">
                    <Input
                      name="phone"
                      v-model="form.phone"
                      type="text"
                      size="large"
                    />

                    <span class="text-sm block text-indigo-600">
                      {{ errors[0] }}
                    </span>
                  </form-item>
                </ValidationProvider>
              </i-col>
              <!-- END PHONE  -->
            </row>

            <!-- START  ADDRESS  -->
            <row :gutter="16">
              <i-col span="12">
                <ValidationProvider
                  name="street"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <form-item label-for="street" label="Street">
                    <Input
                      name="street"
                      v-model="form.address.street"
                      size="large"
                      class="w-full"
                    />
                    <span class="text-sm block text-indigo-600">
                      {{ errors[0] }}
                    </span>
                  </form-item>
                </ValidationProvider>
              </i-col>
              <i-col span="7">
                <ValidationProvider
                  name="parish"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <form-item label-for="parish" label="Parish">
                    <i-select
                      v-model="form.address.parish"
                      size="large"
                      label="Choose"
                      name="parish"
                    >
                      <i-option
                        v-for="parish in form.address.parishes"
                        :value="parish.value"
                        :key="parish.value"
                        >{{ parish.label }}</i-option
                      >
                    </i-select>

                    <span class="text-sm block text-indigo-600">
                      {{ errors[0] }}
                    </span>
                  </form-item>
                </ValidationProvider>
              </i-col>
              <i-col span="5">
                <ValidationProvider
                  name="postal"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <form-item label-for="postal" label="Postal">
                    <Input
                      name="postal"
                      v-model="form.address.postal"
                      size="large"
                      class="w-full"
                    />
                    <span class="text-sm block text-indigo-600">
                      {{ errors[0] }}
                    </span>
                  </form-item>
                </ValidationProvider>
              </i-col>
            </row>
            <row :gutter="16">
              <!-- END ADDERSS  -->

              <!-- START IDTYPE  -->
              <i-col span="12">
                <ValidationProvider
                  name="idtype"
                  rules="required|excluded:Choose"
                  v-slot="{ errors }"
                >
                  <form-item label-for="idtype" label="ID Type">
                    <i-select
                      v-model="form.idtype"
                      size="large"
                      label="Choose"
                      name="idtype"
                    >
                      <i-option
                        v-for="type in form.idtypes"
                        :value="type.value"
                        :key="type.value"
                        >{{ type.label }}</i-option
                      >
                    </i-select>

                    <span class="text-sm block text-indigo-600">
                      {{ errors[0] }}
                    </span>
                  </form-item>
                </ValidationProvider>
              </i-col>
              <!-- END IDTYPE  -->

              <!-- START IDNUMBER  -->
              <i-col span="12">
                <ValidationProvider
                  name="idnumber"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <form-item label-for="idnumber" label="ID Number">
                    <Input
                      name="idnumber"
                      v-model="form.idnumber"
                      size="large"
                      class="w-full"
                    />
                    <span class="text-sm block text-indigo-600">
                      {{ errors[0] }}
                    </span>
                  </form-item>
                </ValidationProvider>
              </i-col>
              <!-- END IDNUMBER  -->
            </row>
            <row :gutter="16">
              <i-col span="24">
                <i-button
                  :disabled="invalid"
                  value="submit"
                  type="primary"
                  name="submit"
                  html-type="submit"
                  label="submit"
                  size="large"
                  long
                  >Submit</i-button
                >
              </i-col>
            </row>
          </i-form>
        </ValidationObserver>
      </i-col>
    </row>

    <!-- END FORM  -->
  </div>
</template>

<script>
export default {
  props: ["slot-key"],
  name: "SendForm",
  data() {
    return {
      form: {
        name: null,
        email: null,
        phone: null,
        address: {
          street: null,
          parish: "Choose",
          parishes: [
            {
              value: "Choose",
              label: "Choose"
            },
            {
              value: "Devonshire",
              label: "Devonshire"
            },
            {
              value: "Pembroke",
              label: "Pembroke"
            },
            {
              value: "Warwick",
              label: "Warwick"
            },
            {
              value: "Paget",
              label: "Paget"
            },
            {
              value: "Southampton",
              label: "Southampton"
            },
            {
              value: "Hamilton",
              label: "Hamilton"
            },
            {
              value: "St. George's",
              label: "St. George's"
            },
            {
              value: "Sandys",
              label: "Sandys"
            },
            {
              value: "Smith's",
              label: "Smith's"
            }
          ],
          postal: null
        },
        dob: "01-01-2019",
        idnumber: null,
        idtype: "Choose",
        idtypes: [
          {
            value: "Choose",
            label: "Choose"
          },
          {
            value: "Passport",
            label: "Passport"
          },
          {
            value: "Driver's License",
            label: "Driver's License"
          },
          {
            value: "Government ID",
            label: "Government ID"
          },
          {
            value: "Other",
            label: "Other"
          }
        ]
      }
    };
  },
  methods: {
    confirm: function() {
      this.$router.push("/signup/thankyou/");
    }
  }
};
</script>

<style scoped></style>
