<template>
  <div class="bg-gray-100">
    <row>
      <i-col span="24">
        <nav-light />
      </i-col>
    </row>
    <row>
      <i-col span="4" class="h-screen p-5">
        <ul class="m-2">
          <li class="bg-white my-3 p-2 rounded shadow font-bold">
            <i class="far fa-eye mr-3 text-brand-1"></i>Insights
          </li>
          <li class="my-3 p-2 rounded font-bold">
            <i class="far fa-folders mr-3"></i>Accounts
          </li>
          <li class="my-3 p-2 rounded font-bold">
            <i class="far fa-download mr-3"></i>Deposits
          </li>
        </ul>
      </i-col>
      <i-col span="20">
        <div class="">22323</div>
      </i-col>
    </row>
  </div>
</template>

<script>
import NavLight from "./NavLight.vue";

export default {
  name: "Main",
  components: {
    NavLight
  },
  data() {
    return {};
  }
};
</script>

<style scoped></style>
