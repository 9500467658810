<template>
  <div id="app">
    <transition name="view" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  name: "app"
};
</script>

<style>
/* .view-enter-active,
.view-leave-active {
  transition: opacity 0.5s ease-in-out;
  -webkit-transition: opacity 0.5s ease-in-out;

  transition-delay: 0.5s;
  -webkit-transition-delay: 0.5s;
} */

/* .view-enter-active {
  transition-delay: 0.5s;
} */

/* .view-enter,
.view-leave-to {
  opacity: 0;
}

.view-enter-to,
.view-leave {
  opacity: 1;
} */
</style>
