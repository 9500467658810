<template>
  <row>
    <i-col span="24">
      <div class="Landing">
        <div class="flex h-full h-screen fs-split">
          <div class="w-1/2 split-image-1 split-video">
            <video
              class="video xs:hidden sm:hidden md:hidden lg:block xl:block"
              muted
              autoplay
              loop
              preload="auto"
              src="../assets/videos/s2g-vid-full.mp4"
            ></video>
            <router-link to="/">
              <img class src="../assets/images/logo-light-sm.svg" />
            </router-link>
          </div>
          <!-- Content Side -->

          <div
            class="flex items-center justify-center w-1/2 min-h-full overflow-auto split-content bg-blue-dark"
          >
            <div class="max-w-lg px-4 mt-auto mb-auto split-content-vertically-center p-80">
              <div class="text-6xl split-intro">
                <img class="my-10" src="../assets/images/logo-splash.svg" width="60" />

                <h1 class="text-5xl font-bold leading-snug tracking-tight text-white">
                  A piggybank
                  <br />for good times.
                </h1>
              </div>

              <div class="text-lg split-1 font-roboto text-blue-light">
                <p class="my-10">
                  Putting aside money for that much needed vacation is of course
                  a neccessary investment towards your peace of mind. With Trip
                  Travel’s free Save2Go service you’ll save like a pro.
                </p>

                <Button
                  type="primary"
                  size="large"
                  html-type="submit"
                  class="w-full"
                  name="submit"
                  to="signup"
                  label="submit"
                >GET STARTED</Button>
              </div>
            </div>
          </div>
          <div class="absolute bottom-0 right-0 flex items-end w-1/2">
            <span
              class="items-center px-6 py-4 ml-auto text-xs text-white opacity-25"
            >&copy; Trip Travel 2021</span>
          </div>
        </div>
      </div>
    </i-col>
  </row>
</template>

<script>
export default {
  name: "Landing"
};
</script>

<style>
.ivu-btn span {
  font-weight: 600;
  font-size: 14px;
}

.split-image-1 {
  /* background-image: url("https://images.unsplash.com/photo-1572937130135-932dd47a0f20?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1600&q=80"); */
  background-color: #000000;
}
</style>
