<template>
  <row>
    <i-col span="24">
      <div class="signup">
        <div class="fs-split">
          <!-- Content Side -->
          <public-header />
          <div class="split-content flex h-screen justify-center overflow-auto">
            <div class="split-content-vertically-center mt-auto mb-auto">
              <div class="split-1io text-xl text-pale-blue">
                <!-- Start Form -->

                <send-form />
              </div>
            </div>
          </div>
        </div>
      </div>
    </i-col>
  </row>
</template>

<script>
import SendForm from "./SendForm.vue";
import PublicHeader from "./PublicHeader.vue";

export default {
  name: "SignUp",
  components: {
    SendForm,
    PublicHeader
  }
};
</script>

<style>
.split-image-2 {
  background-image: url("../assets/images/photo2.jpg");
}
</style>
